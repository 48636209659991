<template>
    <div class="detail-container">
        <div class="good-selection-box">
            <span style="margin-right: 20px">商品信息：</span>
            <div class="good-img">
                <img style="width:48px;height:48px;border-radius: 8px" :src="$route.query.src" alt="">
            </div>
            <div style="margin-left:10px">{{ $route.query.name }}</div>
        </div>
        <div class="card-box">
            <div class="card-item">
                <div class="card-title">引流漏斗</div>
                <div class="card-list">
                    <div class="card-line">
                        <div class="title">
                            <i class="iconfont">&#xe6a7;</i>
                            浏览量
                        </div>
                        <div class="text">{{ flowBase.visit }}</div>
                    </div>
                    <div class="card-line">
                        <div class="title">
                            <i class="iconfont">&#xe879;</i>
                            成交件数指数
                        </div>
                        <div class="text">{{ flowBase.deal }}</div>
                    </div>
                    <div class="card-line">
                        <div class="title">
                            <i class="iconfont">&#xe83e;</i>
                            点击指数
                        </div>
                        <div class="text">{{ flowBase.click }}</div>
                    </div>
                </div>
            </div>
            <div class="card-item">
                <div class="card-title">成交转化</div>
                <div class="card-list">
                    <div class="card-line">
                        <div class="title">
                            <i class="iconfont">&#xe6a7;</i>
                            成交金额指数
                        </div>
                        <div class="text">{{ dealBase.money }}</div>
                    </div>
                    <div class="card-line">
                        <div class="title">
                            <i class="iconfont">&#xe879;</i>
                            成交单量指数
                        </div>
                        <div class="text">{{ dealBase.order }}</div>
                    </div>
                    <div class="card-line">
                        <div class="title">
                            <i class="iconfont">&#xe83e;</i>
                            成交转化率
                        </div>
                        <div class="text">{{ dealBase.conversion }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-box">
            <div class="data-title">引流漏斗</div>
            <div id="dataEcharts" style="height: 400px"></div>
        </div>
        <div class="data-box">
            <div class="data-title">成交转化</div>
            <div id="conversionEcharts" style="height: 400px"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { getGoodInfo } from "@/utils/apis";
export default {
    data() {
        return {
            dataEcharts: null,
            conversionEcharts: null,
            flowBase: {},
            flowTable: {},
            dealBase: {},
            dealTable: {}
        }
    },
    methods: {
        getEchartData() {
            getGoodInfo({ id: this.$route.query.id }).then((res) => {
                if (res.data) {
                    this.flowBase = res.data.flow.base
                    this.flowTable = res.data.flow.table
                    this.dealBase = res.data.deal.base
                    this.dealTable = res.data.deal.table
                    this.getFlowEcharts()
                    this.getConversionEcharts()
                }
            })

        },
        getFlowEcharts() {
            this.dataEcharts.setOption({
                legend: {
                    data: ['浏览量', '点击指数']
                },
                xAxis: {
                    type: 'category',
                    data: this.getDates(),
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '浏览量',
                        axisLine: {    // 坐标轴 轴线
                            show: true,  // 是否显示
                            lineStyle: {
                                color: '#C9CDD4',
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#E5E6EB',
                                type: 'dashed'
                            }
                        },
                    },
                    {
                        type: 'value',
                        name: '点击指数',
                        axisLine: {    // 坐标轴 轴线
                            show: true,  // 是否显示
                            lineStyle: {
                                color: '#C9CDD4',
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#E5E6EB',
                                type: 'dashed'
                            }
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: '#fff'
                    },
                    extraCssText: 'background-color:rgba(0,0,0,0.7);'
                },
                grid: {
                    top: 40,
                    left: 80,
                    right: 60,
                    bottom: 25,
                },
                series: [
                    {
                        name: '浏览量',
                        type: 'line',
                        data: this.flowTable.visit,
                        color: ['#71A3FF'],
                        smooth: true,
                    },
                    {
                        name: '点击指数',
                        type: 'line',
                        data: this.flowTable.click,
                        color: ['#9F8BFF'],
                        smooth: true,
                        yAxisIndex: 1, //在单个图表实例中存在多个y轴的时候有用
                    },
                ]
            })
        },
        getConversionEcharts() {
            this.conversionEcharts.setOption({
                legend: {
                    data: ['成交金额指数', '成交单量指数']
                },
                xAxis: {
                    type: 'category',
                    data: this.getDates(),
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '成交金额指数',
                        axisLine: {    // 坐标轴 轴线
                            show: true,  // 是否显示
                            lineStyle: {
                                color: '#C9CDD4',
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#E5E6EB',
                                type: 'dashed'
                            }
                        },
                    },
                    {
                        type: 'value',
                        name: '成交单量指数',
                        axisLine: {    // 坐标轴 轴线
                            show: true,  // 是否显示
                            lineStyle: {
                                color: '#C9CDD4',
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#E5E6EB',
                                type: 'dashed'
                            }
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: '#fff'
                    },
                    extraCssText: 'background-color:rgba(0,0,0,0.7);'
                },
                grid: {
                    top: 40,
                    left: 80,
                    right: 60,
                    bottom: 25,
                },
                series: [
                    {
                        name: '成交金额指数',
                        type: 'bar',
                        data: this.dealTable.money,
                        color: ['#71A3FF'],
                        barWidth: 8,
                        itemStyle: {
                            borderRadius: [6, 6, 0, 0],
                        }
                    },
                    {
                        name: '成交单量指数',
                        type: 'bar',
                        data: this.dealTable.order,
                        color: ['#9F8BFF'],
                        barWidth: 8,
                        yAxisIndex: 1, //在单个图表实例中存在多个y轴的时候有用
                        itemStyle: {
                            borderRadius: [6, 6, 0, 0],
                        }
                    },
                ]
            })
        },
        getDates() {
            let currentDate = new Date();
            let datesArray = [];
            let currentDateString = (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
            datesArray.push(currentDateString);
            for (let i = 1; i <= 30; i++) {
                let pastDate = new Date(currentDate);
                pastDate.setDate(currentDate.getDate() - i);
                let pastDateString = (pastDate.getMonth() + 1) + '-' + pastDate.getDate();
                datesArray.unshift(pastDateString);
            }
            return datesArray;
        }
    },
    mounted() {
        this.getEchartData()
        this.dataEcharts = echarts.init(document.getElementById('dataEcharts'))
        this.conversionEcharts = echarts.init(document.getElementById('conversionEcharts'))
        let resizeTimer = null
        const self = this;
        window.addEventListener("resize", function () {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                self.dataEcharts.resize();
                self.conversionEcharts.resize();
            }, 100);
        });
        self.getFlowEcharts()
        self.getConversionEcharts()
    },
}
</script>

<style lang="scss" scoped>
.detail-container {
    padding: 20px;
    background-color: #f2f4f7;
    height: 100%;

    .good-selection-box {
        font-size: 18px;
        color: #000000;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .good-img {
            border-radius: 5px;
        }
    }

    .card-box {
        display: flex;
        flex-wrap: wrap;
        margin-left: -28px;

        .card-item {
            flex: 1;
            background: #ffffff;
            margin: 0 0 28px 28px;
            border: 1px solid #EEEFF0;
            border-radius: 4px;
            padding: 20px 24px;

            .card-title {
                font-size: 18px;
                margin-bottom: 30px;
            }

            .card-list {
                display: flex;
                justify-content: space-around;

                .card-line {
                    .title {
                        white-space: nowrap;
                    }

                    .iconfont {
                        color: #409eff;
                        margin-right: 4px;
                    }

                    .text {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .data-box {
        background: #ffffff;
        border: 1px solid #EEEFF0;
        border-radius: 4px;
        padding: 20px 24px;

        &+.data-box {
            margin-top: 28px;
        }

        .data-title {
            margin-bottom: 28px;
            font-size: 18px;
        }
    }
}
</style>